import { ScoreApi } from './score.api'
import { EmployeesApi } from './employees.api'

export interface IApi {
  employees: EmployeesApi
  score: ScoreApi
}

export default (): IApi => {
  const employees = new EmployeesApi()
  const score = new ScoreApi()

  return { employees, score }
}
