import { AcFightArena, HomeView } from '@views'

export const ROUTE_PATHS = {
  HOME: '/',
  FIGHT_ARENA: '/fight',
}

export const ROUTES = [
  {
    path: ROUTE_PATHS.HOME,
    element: <HomeView />,
    private: false,
  },
  {
    path: ROUTE_PATHS.FIGHT_ARENA,
    element: <AcFightArena />,
    private: false,
  },
]
