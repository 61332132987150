import Api, { IApi } from '@api'
import {
  removeFromStorage,
  saveToStorage,
} from '@helpers/browser-storage.helpers'
import { action, makeAutoObservable } from 'mobx'
import { createContext } from 'react'
import { EmployeesStore } from './employees.stores'

export class Store {
  [key: string]: any
  api: IApi
  employees: EmployeesStore

  constructor() {
    makeAutoObservable(this)
    this.api = Api()
    this.employees = new EmployeesStore(this)
  }

  set = action(
    (store: string, key: string, value: unknown, save?: boolean): unknown => {
      if (!key || !store) return
      this[store][key] = value
      if (!value) return removeFromStorage(key)
      if (save) saveToStorage(key, value)
    }
  )
}

export const StoreContext = createContext<Store>(new Store())
