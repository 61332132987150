import { FC, useMemo, forwardRef } from 'react'
import styles from './ac-text-input.module.scss'

interface IAcTextInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string
  id: string
  className?: string
  type: 'text' | 'email' | 'password' | 'url' | 'number' | 'tel' | 'search'
  startAdornment?: () => JSX.Element
  endAdornment?: () => JSX.Element
}

interface IAcTextareaInputProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  name: string
  id: string
  className?: string
  type?: 'textarea'
}

const AcTextInputWrapper: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <div className={styles['ac-text-input-wrapper']}>{children}</div>
}

const AcTextInput = forwardRef<
  HTMLElement,
  IAcTextInputProps | IAcTextareaInputProps
>(({ type = 'text', ...props }, ref) => {
  if (type === 'textarea') {
    const { name, className, ...inputProps } = props as IAcTextareaInputProps
    return (
      <AcTextInputWrapper>
        <textarea
          ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
          {...inputProps}
          name={name}
          className={className}
        />
      </AcTextInputWrapper>
    )
  } else {
    const {
      name,
      className,
      startAdornment: InputStartAdornment,
      endAdornment: InputEndAdornment,
      ...inputProps
    } = props as IAcTextInputProps

    const getTextInputClassNames = useMemo(
      () => [className, styles['ac-text-input']].join(' '),
      [className]
    )

    return (
      <AcTextInputWrapper>
        {InputStartAdornment && <InputStartAdornment />}
        <input
          ref={ref as React.ForwardedRef<HTMLInputElement>}
          {...inputProps}
          type={type}
          name={name}
          className={getTextInputClassNames}
        />
        {InputEndAdornment && <InputEndAdornment />}
      </AcTextInputWrapper>
    )
  }
})
AcTextInput.displayName = 'AcTextInput'

export { AcTextInput }
