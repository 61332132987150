import { IEmployee } from './../typings/employee.typings.d'
import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'

export class EmployeesApi extends AxiosBaseApi {
  getEmployees = (): Promise<{ data: IEmployee[] }> =>
    this.api
      .get(ENDPOINTS.EMPLOYEES.LIST())
      .then(response => response.data.data)
  getEmployeeById = (id: number): Promise<IEmployee> =>
    this.api.get(ENDPOINTS.EMPLOYEES.GET(id)).then(response => response.data)
}
