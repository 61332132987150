import clsx from 'clsx'
import { ButtonHTMLAttributes } from 'react'

import styles from './ac-employee-card.module.scss'

interface IAcEmployeeCard extends ButtonHTMLAttributes<HTMLButtonElement> {
  name: string
  img_url: string
  state: string | null
  stamped?: boolean
}

export const AcEmployeeCard = ({
  name,
  img_url,
  state,
  stamped,
  ...props
}: IAcEmployeeCard) => {
  return (
    <button
      className={clsx(
        styles['ac-employee-card'],
        state && styles['ac-employee-card--' + state],
        stamped && styles['ac-employee-card--stamped']
      )}
      {...props}>
      <img
        src={img_url}
        alt={'Avatar ' + name}
      />
      {name}
    </button>
  )
}
