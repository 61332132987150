import clsx from 'clsx'
import { useMemo } from 'react'
import styles from './ac-typography.module.scss'

interface IAcTypography {
  children: React.ReactNode
  size?: 'md' | 'sm' | 'xs'
  weight?: 'semibold' | 'regular' | 'light'
  className?: string
  element?: 'p' | 'span'
}

export const AcTypography = ({
  children,
  size = 'md',
  weight = 'regular',
  className,
  element: Element = 'p'
}: IAcTypography) => {
  const getTypographyClassNames = useMemo(
    () =>
      clsx(
        styles['ac-typography'],
        styles[`ac-typographt--${Element}`],
        styles[`ac-typography--${size}`],
        styles[`ac-typography--${weight}`],
        className && className
      ),
    [Element, size, weight]
  )

  return <Element className={getTypographyClassNames}>{children}</Element>
}
