export const KEYS = {
  LOADING: 'loading',
  EMPLOYEES: 'employees',
  VICTIM: 'victim',
  HERO: 'hero',
  HUD_QUERY: 'hud_query',
}
export const STORE_KEYS = {
  EMPLOYEES: 'employees',
}
