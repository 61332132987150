import {AxiosBaseApi} from '@config/axios.config'
import {ENDPOINTS} from '@constants'
import {IExample} from '@typings'

export class ScoreApi extends AxiosBaseApi {
  getScore = (): Promise<IExample[]> =>
    this.api.get(ENDPOINTS.SCORE.LIST()).then(response => response.data)
  postScore = (hero_id: number, victim_id: number): Promise<IExample> =>
    this.api.post(ENDPOINTS.SCORE.POST(), {
      hero_id,
      victim_id
    }).then(({data}) => data)
}
