export const LABELS = {
  TIKJAS: 'Tikjas',
  TIKJAS_2: 'Tikjas 2.0',
  YOU_GOT_TIKJASSED: 'Je bent getikjast',
  CHARACTER_SELECTION: 'Selecteer spelers',
  SELECT: 'Selecteer',
  VICTIM: 'getikte',
  HERO: 'jasser',
  IN_SPE: 'in spe',
  VERSUS: 'versus',
}
