import { ROUTES } from '@constants'
import { useMemo } from 'react'
import { Route, PathRouteProps, Routes } from 'react-router-dom'
import { AcDefaultLayout } from '@layouts'

const AcRouter = () => {
  const renderRoutes = useMemo(() => {
    const routeCollection = []
    const generateRouteComponent = ({
      element,
      id,
      path,
      parent,
    }: { parent?: JSX.Element } & PathRouteProps) => (
      <Route
        path="/"
        element={parent ? parent : <AcDefaultLayout />}>
        <Route
          key={id}
          id={id}
          element={element}
          path={path}
        />
      </Route>
    )

    for (const Route of ROUTES) {
      routeCollection.push(generateRouteComponent(Route))
    }
    return routeCollection
  }, [])

  return <Routes>{renderRoutes}</Routes>
}

export { AcRouter }
