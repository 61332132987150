import { useMemo, useEffect } from 'react'
import { AcEmployeeCard } from '../index.features.components'
import { IEmployee } from 'src/typings/employee.typings'

import styles from './ac-employee-card-grid.module.scss'
import { KEYS, STORE_KEYS } from '@constants'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'

type IAcEmployeeCardGrid = {
  victim: integer | undefined
  hero: integer | undefined
  highlighted: IEmployee[]
}

export const AcEmployeeCardGrid = observer(
  ({ victim, hero, highlighted }: IAcEmployeeCardGrid) => {
    const { set, employees } = useStore()

    const onEmployeeClick = (employee_id: IEmployee['id']) => {
      if (!employees.victim) {
        employees.set_victim(employee_id)
      } else if (employees.victim === employee_id) {
        employees.set_victim(undefined)
      } else {
        set(STORE_KEYS.EMPLOYEES, KEYS.HUD_QUERY, '')
        employees.set_hero(employee_id)
      }
    }

    const renderEmployeeCards = useMemo(() => {
      const employeeCards = []
      for (const employee of employees.employees) {
        let state = null
        let stamped = false

        if (employee.id === victim) {
          state = 'victim'
          stamped = true
        } else if (employee.id === hero) {
          state = 'hero'
          stamped = true
        } else if (!hero && highlighted.includes(employee)) {
          state = 'victim'
        }

        if (highlighted.length === 1 && !victim) {
          stamped = true
        }

        employeeCards.push(
          <AcEmployeeCard
            name={employee.name}
            img_url={employee.img_url}
            onClick={() => onEmployeeClick?.(employee.id)}
            state={state}
            stamped={stamped}
          />
        )
      }
      return employeeCards
    }, [employees, hero, victim, highlighted])

    useEffect(() => {
      if (
        highlighted.length === 1 &&
        highlighted[0].id !== hero &&
        highlighted[0].id !== victim
      ) {
        onEmployeeClick?.(highlighted[0].id)
      }
    }, [highlighted, victim])

    return (
      <div className={styles['ac-employee-card-grid']}>
        {renderEmployeeCards}
      </div>
    )
  }
)
