import { IEmployee } from '../typings/employee.typings'

import { action, computed, makeAutoObservable } from 'mobx'

import { KEYS, STORE_KEYS } from '@constants'
import { autoLoad } from '@helpers/browser-storage.helpers'
import { Store } from '@stores'
import Fuse from 'fuse.js'

export class EmployeesStore {
  store: Store

  employees: IEmployee[]
  loading: boolean
  victim?: IEmployee['id']
  hero?: IEmployee['id']
  hud_query: string

  constructor(store: Store) {
    makeAutoObservable(this)

    this.store = store

    this.employees = []
    this.loading = false
    this.hud_query = ''

    autoLoad(this, KEYS.VICTIM)
  }

  get_list = action(async () => {
    try {
      this.store.set(STORE_KEYS.EMPLOYEES, KEYS.LOADING, true)
      const employees = await this.store.api.employees.getEmployees()
      await this.store.set(STORE_KEYS.EMPLOYEES, KEYS.EMPLOYEES, employees)
    } catch (e) {
      //error handling
      console.error(e)
    } finally {
      this.store.set(STORE_KEYS.EMPLOYEES, KEYS.LOADING, false)
    }
  })

  set_hero = action(async (employee_id: IEmployee['id'] | null = null) => {
    if (this.loading || !this.employees.length) return

    try {
      this.store.set(STORE_KEYS.EMPLOYEES, KEYS.HERO, employee_id, true)
    } catch (e) {
      //error handling
      console.error(e)
    } finally {
      void 0
    }
  })

  set_victim = action(async (employee_id: IEmployee['id'] | null = null) => {
    if (this.loading || !this.employees.length) return

    try {
      this.store.set(STORE_KEYS.EMPLOYEES, KEYS.VICTIM, employee_id, true)
    } catch (e) {
      //error handling
      console.error(e)
    } finally {
      void 0
    }
  })

  fight = action(async () => {
    if (this.loading || !this.employees.length || !this.hero) return

    try {
      this.store.set(STORE_KEYS.EMPLOYEES, KEYS.LOADING, true)
      await this.store.api.score.postScore(this.hero, this.victim ?? 0)
    } catch (e) {
      //error handling
      console.error(e)
    } finally {
      this.store.set(STORE_KEYS.EMPLOYEES, KEYS.LOADING, false)
    }
  })

  @computed
  get get_employees_fuse() {
    return new Fuse(this.employees, {
      keys: ['name'],
      threshold: 0.1,
    })
  }

  @computed
  get get_highlighted_by_hud() {
    return this.get_employees_fuse
      .search(this.hud_query)
      .map(({ item }) => item)
  }

  @computed
  get get_victim() {
    return this.employees.find(e => e.id === this.victim)
  }

  @computed
  get get_hero() {
    return this.employees.find(e => e.id === this.hero)
  }
}
