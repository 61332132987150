import { AcIcon, AcTitle } from '@components'
import { LABELS } from '@constants'

import styles from './ac-tikjas.module.scss'

export const AcTikjas = () => {
  return (
    <section className={styles['ac-tikjas']}>
      <AcTitle
        level={1}
        className={styles['ac-tikjas-glitch']}
        dataText={`${LABELS.TIKJAS}!`}>
        {LABELS.TIKJAS}!
      </AcTitle>
      <AcIcon
        className={styles['ac-tikjas-tick']}
        icon="TICK"
        size="lg"
        fill="#66ff00"
      />
    </section>
  )
}
