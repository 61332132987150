import { Outlet } from 'react-router-dom'
import styles from './ac-default-layout.module.scss'

export const AcDefaultLayout = (): JSX.Element => {
  return (
    <div id="app">
      <div className={styles['ac-default-background']} />
      <main className={styles['ac-default-layout']}>
        <Outlet />
      </main>
    </div>
  )
}
