import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

import { AcEmployeeCardGrid, AcFight, AcHud, AcTitle } from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks/use-store'

import styles from './ac-home.module.scss'

export const HomeView = observer((): JSX.Element => {
  const { employees } = useStore()

  const getEmployees = async () => await employees.get_list()

  const targetTypeLabel = useMemo(() => {
    return !employees.victim ? LABELS.VICTIM : LABELS.HERO
  }, [employees.victim])

  useEffect(() => {
    getEmployees()
  }, [])

  return (
    <section className={styles['ac-home']}>
      <div>
        <AcTitle>{LABELS.TIKJAS_2}</AcTitle>
        <AcTitle
          className={styles['ac-home-subtitle']}
          level={2}>
          {LABELS.SELECT} <span>{targetTypeLabel}</span> {LABELS.IN_SPE}
        </AcTitle>
      </div>
      <AcEmployeeCardGrid
        victim={employees.victim}
        hero={employees.hero}
        highlighted={employees.get_highlighted_by_hud}
      />
      <AcFight />
      <AcHud />
    </section>
  )
})
