import clsx from 'clsx'
import { createElement, useMemo } from 'react'
import styles from './ac-title.module.scss'

interface IAcTitle {
  children: React.ReactNode
  level?: 1 | 2 | 3
  className?: string
  dataText?: string
}

export const AcTitle = ({
  children,
  level = 1,
  className,
  dataText,
}: IAcTitle) => {
  const getTitleClassNames = useMemo(
    () => clsx(styles['ac-title'], styles[`ac-title--${level}`], className),
    [level, className]
  )

  const element = useMemo(
    () =>
      createElement(
        `h${level}`,
        { className: getTitleClassNames, 'data-text': dataText },
        children
      ),
    [level, children, getTitleClassNames]
  )

  return element
}
