import { ICON_ELEMENTS } from '@constants'

import styles from './ac-icon.module.scss'

interface IAcIconProps {
  icon: keyof typeof ICON_ELEMENTS
  size?: 'sm' | 'md' | 'lg'
  className?: string
  fill?: string
}

export const AcIcon = ({
  icon,
  size = 'md',
  className,
  fill,
}: IAcIconProps) => {
  const Icon = ICON_ELEMENTS[icon]

  if (!Icon) return null
  return (
    <Icon
      className={`${styles['ac-icon']} ${styles[`ac-icon--${size}`]} ${
        className ? className : ''
      }`}
      style={{ fill }}
    />
  )
}
