import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useRef } from 'react'

import { useStore } from '@hooks/use-store'
import { LABELS } from '../../../constants/labels.constants'
import { AcButton } from '../../core/ac-button/ac-button'
import { AcTitle } from '../../core/ac-title/ac-title'
import { AcEmployeeCard } from '../ac-employee-card/ac-employee-card'

import styles from './ac-fight.module.scss'
import modalBackground from '@assets/images/blurp_bg.svg'
import { useNavigate } from 'react-router-dom'

export const AcFight = observer(() => {
  const { employees } = useStore()
  const navigate = useNavigate()
  const AcFightModalRef = useRef<HTMLDialogElement>(null)

  const handleDialogClick = ({ target }: React.MouseEvent) => {
    const { tagName } = target as HTMLDialogElement

    if (tagName === 'DIALOG' && AcFightModalRef?.current) {
      employees.set_hero(undefined)
      AcFightModalRef.current.close()
    }
  }

  const handleDialogCloseByEsc = (
    event: React.SyntheticEvent<HTMLDialogElement, Event>
  ) => {
    event.preventDefault()
    employees.set_hero(undefined)
    AcFightModalRef.current?.close()
  }

  const handleFightClick = () => {
    employees.fight()

    const doc: any = document.documentElement

    if (doc.requestFullscreen) {
      doc.requestFullscreen()
    } else if (doc.webkitRequestFullscreen) {
      doc.webkitRequestFullscreen()
    }

    navigate('/fight')
  }

  useEffect(() => {
    // Preload background image
    const background = new Image()
    background.src = modalBackground
  }, [])

  useEffect(() => {
    if (AcFightModalRef) {
      AcFightModalRef.current?.showModal()
    }
  }, [employees.hero])

  const renderFightModal = useMemo(() => {
    const hero = employees.get_hero
    const victim = employees.get_victim

    if (!hero || !victim) return

    return (
      <dialog
        className={styles['ac-fight_modal']}
        ref={AcFightModalRef}
        onClick={handleDialogClick}
        onClose={handleDialogCloseByEsc}>
        <div>
          <AcEmployeeCard
            name={victim.name}
            img_url={victim.img_url}
            state={'victim'}
            stamped
          />

          <AcTitle
            className={styles['ac-fight-versus']}
            level={2}>
            {LABELS.VERSUS}
          </AcTitle>

          <AcEmployeeCard
            name={hero.name}
            img_url={hero.img_url}
            state={'hero'}
          />
        </div>
        <div>
          <AcButton
            onClick={handleFightClick}
            label={'Fight!'}
          />
        </div>
      </dialog>
    )
  }, [employees.hero])

  return <>{renderFightModal}</>
})
