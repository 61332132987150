import axios, { AxiosInstance } from 'axios'
import ApiConfig from '@config/api.config'

export class AxiosBaseApi {
  api: AxiosInstance
  download: AxiosInstance
  upload: AxiosInstance

  addInterceptor = (instance: AxiosInstance): void => {
    instance.interceptors.response.use(
      response => response,
      error => {
        const originalRequest = error.config
        const { status } = error.response
        console.error('response error: ', originalRequest, status)

        // Example for handling internal error
        if (status === 500) {
          const internalError = new Event('internalError')
          window.dispatchEvent(internalError)
        }

        // Handle default error
        return Promise.reject(error)
      }
    )
  }

  constructor() {
    const _api = axios.create(ApiConfig.api)
    this.addInterceptor(_api)
    this.api = _api
    this.download = axios.create(ApiConfig.download)
    this.upload = axios.create(ApiConfig.upload)
  }
}
