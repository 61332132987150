import { ReactComponent as AngleDownIcon } from '@assets/icons/AngleDown.svg'
import { ReactComponent as AngleLeftIcon } from '@assets/icons/AngleLeft.svg'
import { ReactComponent as AngleRightIcon } from '@assets/icons/AngleRight.svg'
import { ReactComponent as AngleUpIcon } from '@assets/icons/AngleUp.svg'
import { ReactComponent as BoldIcon } from '@assets/icons/Bold.svg'
import { ReactComponent as BookIcon } from '@assets/icons/Book.svg'
import { ReactComponent as BuildingsIcon } from '@assets/icons/Buildings.svg'
import { ReactComponent as CalendarIcon } from '@assets/icons/Calendar.svg'
import { ReactComponent as ChartIcon } from '@assets/icons/Chart.svg'
import { ReactComponent as CheckIcon } from '@assets/icons/Check.svg'
import { ReactComponent as CloseIcon } from '@assets/icons/Close.svg'
import { ReactComponent as ContactsIcon } from '@assets/icons/Contacts.svg'
import { ReactComponent as DashboardIcon } from '@assets/icons/Dashboard.svg'
import { ReactComponent as DateAltIcon } from '@assets/icons/Date-1.svg'
import { ReactComponent as DateIcon } from '@assets/icons/Date.svg'
import { ReactComponent as DeleteIcon } from '@assets/icons/Delete.svg'
import { ReactComponent as EditIcon } from '@assets/icons/Edit.svg'
import { ReactComponent as EmailIcon } from '@assets/icons/Email.svg'
import { ReactComponent as ErrorIcon } from '@assets/icons/Error.svg'
import { ReactComponent as EuroAltIcon } from '@assets/icons/Euro-1.svg'
import { ReactComponent as EuroIcon } from '@assets/icons/Euro.svg'
import { ReactComponent as ExportIcon } from '@assets/icons/Export.svg'
import { ReactComponent as EyesIcon } from '@assets/icons/Eyes.svg'
import { ReactComponent as FilterIcon } from '@assets/icons/Filter.svg'
import { ReactComponent as GoogleIcon } from '@assets/icons/Google.svg'
import { ReactComponent as HeartIcon } from '@assets/icons/Heart.svg'
import { ReactComponent as HelpIcon } from '@assets/icons/Help.svg'
import { ReactComponent as InboxIcon } from '@assets/icons/Inbox.svg'
import { ReactComponent as InfoIcon } from '@assets/icons/Info.svg'
import { ReactComponent as InvoiceIcon } from '@assets/icons/Invoice.svg'
import { ReactComponent as ItalicIcon } from '@assets/icons/Italic.svg'
import { ReactComponent as LabelsIcon } from '@assets/icons/Labels.svg'
import { ReactComponent as LinkIcon } from '@assets/icons/Link.svg'
import { ReactComponent as ListIcon } from '@assets/icons/List.svg'
import { ReactComponent as ListNrIcon } from '@assets/icons/ListNr.svg'
import { ReactComponent as MailIcon } from '@assets/icons/Mail.svg'
import { ReactComponent as MenuIcon } from '@assets/icons/Menu.svg'
import { ReactComponent as MoreIcon } from '@assets/icons/More.svg'
import { ReactComponent as NotificationIcon } from '@assets/icons/Notification.svg'
import { ReactComponent as PhoneIcon } from '@assets/icons/Phone.svg'
import { ReactComponent as PlusIcon } from '@assets/icons/Plus.svg'
import { ReactComponent as QuestionIcon } from '@assets/icons/Question.svg'
import { ReactComponent as RotateLeftIcon } from '@assets/icons/RotateLeft.svg'
import { ReactComponent as RotateRightIcon } from '@assets/icons/RotateRight.svg'
import { ReactComponent as SearchIcon } from '@assets/icons/Search.svg'
import { ReactComponent as SettingsIcon } from '@assets/icons/Settings.svg'
import { ReactComponent as StackIcon } from '@assets/icons/Stack.svg'
import { ReactComponent as StoreIcon } from '@assets/icons/Store.svg'
import { ReactComponent as SupportIcon } from '@assets/icons/Support.svg'
import { ReactComponent as TicketIcon } from '@assets/icons/Ticket.svg'
import { ReactComponent as TruckIcon } from '@assets/icons/Truck.svg'
import { ReactComponent as UserIcon } from '@assets/icons/User.svg'
import { ReactComponent as UsersAltIcon } from '@assets/icons/Users-v2.svg'
import { ReactComponent as UsersIcon } from '@assets/icons/Users.svg'
import { ReactComponent as XCircleIcon } from '@assets/icons/XCircle.svg'
import { ReactComponent as TickIcon } from '@assets/icons/tick.svg'
import { IIconElements, IIcons } from '@typings'

/* ---- ICONS ---- */

/* Whenever you want to use an Icon, we use the <AcIcon /> component. 
We can pass an Icon prop to it. The prop should be ICONS.<key_of_icon>. 
Within the AcIcon component we map the icon string to the correct
icon from ICON_ELEMENTS. */

export const ICON_ELEMENTS: IIconElements = {
  ANGLE_DOWN: AngleDownIcon,
  ANGLE_LEFT: AngleLeftIcon,
  ANGLE_RIGHT: AngleRightIcon,
  ANGLE_UP: AngleUpIcon,
  BOLD: BoldIcon,
  BOOK: BookIcon,
  BUILDINGS: BuildingsIcon,
  CALENDER: CalendarIcon,
  CHART: ChartIcon,
  CHECK: CheckIcon,
  CLOSE: CloseIcon,
  CONTACTS: ContactsIcon,
  DASHBOARD: DashboardIcon,
  DATE_ALT: DateAltIcon,
  DATE: DateIcon,
  DELETE: DeleteIcon,
  EDIT: EditIcon,
  EMAIL: EmailIcon,
  ERROR: ErrorIcon,
  EURO_ALT: EuroAltIcon,
  EURO: EuroIcon,
  EXPORT: ExportIcon,
  EYES: EyesIcon,
  FILTER: FilterIcon,
  GOOGLE: GoogleIcon,
  HEART: HeartIcon,
  HELP: HelpIcon,
  INBOX: InboxIcon,
  INFO: InfoIcon,
  INVOICE: InvoiceIcon,
  ITALIC: ItalicIcon,
  LABELS: LabelsIcon,
  LINK: LinkIcon,
  LIST_NR: ListNrIcon,
  LIST: ListIcon,
  MAIL: MailIcon,
  MENU: MenuIcon,
  MORE: MoreIcon,
  NOTIFICATION: NotificationIcon,
  PHONE: PhoneIcon,
  PLUS: PlusIcon,
  QUESTION: QuestionIcon,
  ROTATE_LEFT: RotateLeftIcon,
  ROTATE_RIGHT: RotateRightIcon,
  SEARCH: SearchIcon,
  SETTINGS: SettingsIcon,
  STACK: StackIcon,
  STORE: StoreIcon,
  SUPPORT: SupportIcon,
  TICK: TickIcon,
  TICKET: TicketIcon,
  TRUCK: TruckIcon,
  USER: UserIcon,
  USER_ALT: UsersAltIcon,
  USERS: UsersIcon,
  X_CIRCLE: XCircleIcon,
}

export const ICONS: IIcons = {
  ANGLE_DOWN: 'ANGLE_DOWN',
  ANGLE_LEFT: 'ANGLE_LEFT',
  ANGLE_RIGHT: 'ANGLE_RIGHT',
  ANGLE_UP: 'ANGLE_UP',
  BOLD: 'BOLD',
  BOOK: 'BOOK',
  BUILDINGS: 'BUILDINGS',
  CALENDER: 'CALENDER',
  CHART: 'CHART',
  CHECK: 'CHECK',
  CLOSE: 'CLOSE',
  CONTACTS: 'CONTACTS',
  DASHBOARD: 'DASHBOARD',
  DATE_ALT: 'DATE_ALT',
  DATE: 'DATE',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  EMAIL: 'EMAIL',
  ERROR: 'ERROR',
  EURO_ALT: 'EURO_ALT',
  EURO: 'EURO',
  EXPORT: 'EXPORT',
  EYES: 'EYES',
  FILTER: 'FILTER',
  GOOGLE: 'GOOGLE',
  HEART: 'HEART',
  HELP: 'HELP',
  INBOX: 'INBOX',
  INFO: 'INFO',
  INVOICE: 'INVOICE',
  ITALIC: 'ITALIC',
  LABELS: 'LABELS',
  LINK: 'LINK',
  LIST_NR: 'LIST_NR',
  LIST: 'LIST',
  MAIL: 'MAIL',
  MENU: 'MENU',
  MORE: 'MORE',
  NOTIFICATION: 'NOTIFICATION',
  PHONE: 'PHONE',
  PLUS: 'PLUS',
  QUESTION: 'QUESTION',
  ROTATE_LEFT: 'ROTATE_LEFT',
  ROTATE_RIGHT: 'ROTATE_RIGHT',
  SEARCH: 'SEARCH',
  SETTINGS: 'SETTINGS',
  STACK: 'STACK',
  STORE: 'STORE',
  SUPPORT: 'SUPPORT',
  TICK: 'TICK',
  TICKET: 'TICKET',
  TRUCK: 'TRUCK',
  USER: 'USER',
  USER_ALT: 'USER_ALT',
  USERS: 'USERS',
  X_CIRCLE: 'X_CIRCLE',
}
