import clsx from 'clsx'
import { RefObject } from 'preact'
import {
  Children,
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { AcTabLabel } from './ac-tab-label'
import { AcTabPanel } from './ac-tab-panel'
import styles from './ac-tabs.module.scss'

// AcTabs
// AcTabNav: onNavigate, disable-router, name
// AcTabLabel: disabled, error, dirty, name
// AcTabPanel

interface IAcTabs {
  tabs: {
    id: string
    label: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: (props?: any) => JSX.Element
    disabled: boolean
  }[]
  children: React.ReactNode
  errors?: string[] | Set<string>
}

interface IAcTabsContext {
  activeTab: string
  errorTabs: string[]
}

type ITabNavLabelRefs = Map<string, RefObject<HTMLButtonElement>>
type IAcTab = <TabProps>(props: TabProps & { name: string }) => null

export const AcTabsContext = createContext<IAcTabsContext>({
  activeTab: '',
  errorTabs: [],
})

export const AcTab: IAcTab = () => null

export const AcTabs = ({ tabs, children, errors }: IAcTabs) => {
  const [activeTab, setActiveTab] = useState(tabs?.[0]?.id || '')

  const tabNavLabelRefs = useRef<ITabNavLabelRefs>(new Map())
  const tabNavSliderRef = useRef<HTMLHRElement>(null)
  const resizeObserverRef = useRef<ResizeObserver>()

  const errorTabs = useMemo(() => [...(errors || [])], [errors])

  const renderTabLabels = useMemo(() => {
    const tabLabels = []

    for (const { label, id } of tabs) {
      const ref = useRef<HTMLButtonElement>(null)
      tabNavLabelRefs.current.set(id, ref)
      tabLabels.push(
        <AcTabLabel
          ref={ref}
          id={id}
          onClick={() => setActiveTab(id)}>
          {label}
        </AcTabLabel>
      )
    }

    return tabLabels
  }, [errors, activeTab])

  const renderTabs = useMemo(() => {
    const tabCollection = []
    const childrenProps = new Map()

    // Combine children props (<AcTab />) with Tab element
    Children.forEach(children as React.ReactElement, child =>
      childrenProps.set(child.props.name, child.props)
    )

    for (const { id, label, component: Tab } of tabs) {
      const childProps = childrenProps.get(id) || {}

      tabCollection.push(
        <AcTabPanel
          id={id}
          label={label}>
          <Tab {...childProps} />
        </AcTabPanel>
      )
    }

    return tabCollection
  }, [activeTab])

  const getTabNavSliderClassNames = useMemo(
    () =>
      clsx([
        styles['ac-tab-nav__slider'],
        errorTabs.includes(activeTab) && styles['ac-tab-nav__slider--error'],
      ]),
    [errorTabs, activeTab]
  )

  // Create ResizeObserver that moves and resizes tabNavSlider
  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver(
      (elements: ResizeObserverEntry[]) => {
        for (const element of elements) {
          if (tabNavSliderRef.current) {
            const tabNavPositionX = (
              tabNavSliderRef.current.parentElement as HTMLElement
            ).getBoundingClientRect().left
            const targetRect = (
              element.target as HTMLElement
            ).getBoundingClientRect()

            tabNavSliderRef.current.style.width = targetRect.width + 'px'
            tabNavSliderRef.current.style.transform = `translateX(${
              targetRect.left - tabNavPositionX
            }px)`
          }
        }
      }
    )
  }, [])

  // Assign activeTab el to ResizeObserver on activeTab change
  useEffect(() => {
    const activeTabElement = tabNavLabelRefs.current.get(activeTab)?.current
    if (resizeObserverRef.current && activeTabElement) {
      resizeObserverRef.current.observe(activeTabElement)
      return () => resizeObserverRef?.current?.unobserve(activeTabElement)
    }
  }, [activeTab])

  return (
    <div className={styles['ac-tabs']}>
      <AcTabsContext.Provider value={{ activeTab, errorTabs }}>
        <div
          className={styles['ac-tab-nav']}
          role="tablist">
          <hr
            ref={tabNavSliderRef}
            className={getTabNavSliderClassNames}
          />
          {renderTabLabels}
        </div>
        {renderTabs}
      </AcTabsContext.Provider>
    </div>
  )
}
