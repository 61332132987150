import clsx from 'clsx'
import { useContext } from 'react'
import { useMemo, forwardRef } from 'react'
import { AcTabsContext } from './ac-tabs'
import styles from './ac-tabs.module.scss'

interface IAcTabLabel {
  children: React.ReactNode
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  id: string
}

const AcTabLabel = forwardRef<HTMLButtonElement, IAcTabLabel>(
  ({ children, onClick, id }, ref) => {
    const { errorTabs, activeTab } = useContext(AcTabsContext)

    const isErrored = useMemo(() => errorTabs.includes(id), [id, errorTabs])
    const isActive = useMemo(() => activeTab === id, [id, activeTab])

    const getTabLabelClassNames = useMemo(
      () =>
        clsx([
          styles['ac-tab-label'],
          isErrored && styles['ac-tab-label--error'],
          isActive && styles['ac-tab-label--active'],
        ]),
      [isErrored, isActive]
    )
    return (
      <button
        ref={ref}
        data-tab={id}
        type="button"
        onClick={onClick}
        className={getTabLabelClassNames}
        role="tab">
        {children}
      </button>
    )
  }
)

AcTabLabel.displayName = 'AcTabLabel'

export { AcTabLabel }
