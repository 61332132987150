const API = '/api'

const EMPLOYEES = '/employees'
const SCORE = '/score'

export const ENDPOINTS = {
  EMPLOYEES: {
    LIST: () => API + EMPLOYEES,
    GET: (ID: number) => `${API}${EMPLOYEES}/${ID}`,
  },
  SCORE: {
    LIST: () => API + SCORE,
    POST: () => API + SCORE,
  },
}
