import { KEYS, STORE_KEYS } from '@constants'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'

import styles from './ac-hud.module.scss'

export const AcHud = observer(() => {
  const inputRef = useRef<HTMLInputElement>(null)
  const store = useStore()

  const handleHudInput = ({ target }: { target: HTMLInputElement }) => {
    store.set(STORE_KEYS.EMPLOYEES, KEYS.HUD_QUERY, target.value)
  }

  useEffect(() => {
    const focusHud = (event: KeyboardEvent) => {
      if (!inputRef.current) return

      if (
        event.key === 'Backspace' &&
        !store.employees.hud_query &&
        store.employees.victim
      ) {
        store.set(STORE_KEYS.EMPLOYEES, KEYS.VICTIM, null)
      }

      if (event.key.length === 1) {
        if (event.target !== inputRef.current)
          store.set(STORE_KEYS.EMPLOYEES, KEYS.HUD_QUERY, '')
        inputRef.current.focus()
      }
    }

    window.addEventListener('keydown', focusHud)

    return () => window.removeEventListener('keydown', focusHud)
  }, [])

  return (
    <div className={styles['ac-hud']}>
      <input
        ref={inputRef}
        autoFocus
        placeholder="JASSER"
        onChange={handleHudInput}
        value={store.employees.hud_query}
      />
    </div>
  )
})
