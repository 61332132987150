import { render } from 'preact'
import 'preact/debug'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import '@styles/index.styles.scss'

import reportWebVitals from '@helpers/report-web-vitals.helpers'

import { AcRouter } from '@components'

const root = document.getElementById('root') as HTMLElement

render(
  <React.StrictMode>
    <BrowserRouter>
      <AcRouter />
    </BrowserRouter>
  </React.StrictMode>,
  root
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
